/* eslint-disable */
<template>
  <div class="edit-booking">
    <Navigation indexProps="2"/>
    <ProfileReadMoreModal ref="profileReadMoreModal"/>
    <div class="top-panel">
      <Header/>
      <div v-if="showContent" class="header-title-section">
        <div class="header-title">
          <h1>Edit Booking {{ booking.id }} for <u>
            <router-link :to="{ name: 'manage-case', query: { id: booking.caseId } }">Case Ref:
              {{ booking.caseReference }}
            </router-link>
          </u></h1>
        </div>
        <div class="header-sub-title">{{ subTitle }}</div>
      </div>
      <div v-else class="header-title-section"><h5>Booking does not exist</h5></div>
    </div>
    <div class="panel">
      <CaseMenu :indexProps="isEdit ? '0' : '1'"/>
      <div class="content" v-if="showContent">
        <div class="top-detail">
          <div class="row m-0">
            <div class="col-4 p-0 center-title">
              <div class="row center-title m-0 display-in-row">
                <div class="col-2 p-0" v-if="booking.bookingDateTime">
                  <div class="border-image">
                    <div class="border-image-number">
                      {{ formatDate(booking.bookingDateTime, 'DD', 'YYYY-MM-DD HH:mm:ss.ssssss') }}
                    </div>
                    <div class="border-image-text">
                      {{ formatDate(booking.bookingDateTime, 'MMM, YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss.ssssss') }}
                    </div>
                  </div>
                </div>
                <div class="col center-title ml-4">
                  {{ getFullLocationWithCountry(this.booking.locationId) }}
                </div>
              </div>
            </div>
            <div class="col-3 center-title p-0">
              <div class="name">{{ booking.Salutation }} {{ booking.claimantFullName }}</div>
              <div>
                <p>D.O.B. {{ formatDate(booking.dateOfBirth, 'DD/MM/YYYY') }}</p>
                <!-- <p>case Commenced: {{formatDate(booking.commencementDate, 'DD/MM/YYYY')}}</p> -->
                <p>Type: {{ booking.claimTypeName }}</p>
              </div>
            </div>
            <div class="col p-0">
              <div class="row m-0">
                <div class="col-2 p-0">
                  <div class="rounded-image medium-rounded-image m-t-0 m-t-b is-icon"
                       @click="tapReadMore(specialist.id)">
                    <div v-if="specialist.profileThumbnail != ''" class="profile-image-content"><img
                        :src="specialist.profileThumbnail"/></div>
                    <div v-else class="profile-image-content notice-background"><s
                        class="text-initials">{{ getInitials(specialist) }}</s></div>
                  </div>
                </div>
                <div class="col center-title">
                  <div>
                    <div class="name is-icon" @click="tapReadMore(specialist.id)">
                      {{ specialist.fullName }}
                    </div>
                    <div class="">
                      {{ getSpecialistInfo(specialist.practiceLocationId, specialist.locations) }}
                    </div>
                    <div class="is-icon">
                      <img v-if="booking.requiresTravel" class="icon-20 mr-2"
                           src="@/assets/images/travel_black_circle_icon.svg"/>
                      <img v-if="booking.videoLinkAvailable == 2" class="icon-20"
                           src="@/assets/images/video_black_icon.svg" data-toggle="tooltip" data-placement="right"
                           title="Appointment by Video Link"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="box-heading col-space-between">
            <div>Booking details</div>
            <div v-if="booking.originaleBookingId" class="mr-5">
              <router-link :to="{ name: 'edit-booking', query: { id: booking.originaleBookingId } }">
                <v-btn dark>{{ booking.originaleBookingId }}</v-btn>
              </router-link>
            </div>
            <v-btn v-if="!booking.originaleBookingId && showSupplementaryButton" class="mr-3" dark
                   @click="tapSupplementaryService">Supplementary Service Request
            </v-btn>
          </div>
          <div class="box-body">
            <div class="box-row disabled-element">
              <div class="box-col box-col-left ">Booking Type</div>
              <div class="box-col box-col-right">
                <select disabled="true" @change="onChangeBookingType($event)" v-model="booking.bookingTypeId"
                        class="textbox">
                  <option hidden value="0">Please choose</option>
                  <option v-for="(bookingType, index) in bookingTypes" :value="bookingType.id">{{
                      bookingType.name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Booking Status</div>
              <div class="box-col box-col-right">
                <select @change="checkBookingAvailability($event)" :disabled="!editBookingStatus" class="textbox"
                        v-model="booking.bookingStatus">
                  <option hidden value="0">Please choose</option>
                  <option v-for="(status, index) in bookingStatus" :value="status.id">{{ status.name }}</option>
                </select>
              </div>
            </div>
            <div v-if="booking.bookingTypeId == 4 || booking.bookingTypeId == 7 || booking.bookingTypeId == 15" class="box-row">
              <div class="box-col box-col-left left-label required">Claimant State During Booking</div>
              <div class="box-col box-col-right">
                <select :disabled="booking.bookingStatus >= 5" class="textbox" v-model="booking.claimantStateDuringBooking"
                        :class="hasError && !validateState ? 'is-invalid' : ''">
                  <option value="" hidden>Please choose</option>
                  <option v-for="state in states" :value="state.value">{{ state.text }}</option>
                </select>
                <span class="pl-4 left-label">Booking Time in Claimant State</span>
                <span type="text" class="w-35 ml-4">{{formatDate(booking.ClaimantBookingDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss")}}</span>
                <div v-if="hasError && !validateState" class="text-danger pl-2">State is required.</div>
              </div>
            </div>
            <!-- <div class="box-row">
              <div  class="box-col box-col-left">Private</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(0)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.private"/>
              </div>
            </div> -->
            <div class="box-row" v-if="booking.bookingTypeId < 5">
              <div class="box-col box-col-left" :class="!disableEditing ? 'disabled-element' : ''">Claimant Attendance
              </div>
              <div class="box-col box-col-right">
                <select :disabled="booking.bookingStatus >= 5" class="textbox" v-model="booking.claimantConfirmed"
                        @change="toggleAttendance()">
                  <option hidden value="0">Please choose</option>
                  <option value="1">Confirmed</option>
                  <option value="2">Not confirmed</option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Specialist</div>
              <div class="box-col box-col-right d-flex justify-content-start align-items-center">
                <span class="px-3 w-25 mr-2">{{ booking.specialistFirstName + ' ' + booking.specialistSurname }}</span>
                <el-tooltip effect="dark" :content="booking.qualification.join(', ')" placement="bottom">
                    <span class="w-25"
                        style="white-space: nowrap; display: block; overflow: hidden;text-overflow: ellipsis; flex: 1">{{
                        booking.qualification.join(', ')
                      }}</span>
                </el-tooltip>
<!--                <router-link v-if="booking.bookingStatus < 5" style="margin: unset" class="mr-8 ml-2"-->
<!--                             :to="{ name: 'availability-bookings', query: { id: booking.specialistId, date: bookingDate } }"-->
<!--                ><img src="@/assets/images/calendar_black_icon.svg"-->
<!--                /></router-link>-->
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left disabled-element">Reference Number</div>
              <div class="box-col box-col-right">
                <input disabled="true" class="textbox disabled-element" type="text" v-model="booking.caseReference"
                       placeholder="Reference Number"/>
              </div>
            </div>
            <!--            <div class="box-row">-->
            <!--              <div class="box-col box-col-left">On behalf of</div>-->
            <!--              <div class="box-col box-col-right">-->
            <!--                <input @keyup="onChangeBehalfOf" class="textbox" maxlength="255" type="text" v-model="booking.onBehalfOf" placeholder="On behalf of" />-->
            <!--                <div v-if="hasError && !validOnBehalfOf" class="text-danger">Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '&#45;&#45;' or '||' or '@@' </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="box-row">
              <div class="box-col box-col-left">Date of Accident/Event</div>
              <div class="box-col box-col-right box-col-center">
                <div class="w-75">
                  <div class="right-float-element" style="line-height: 40px;">
                    <toggle-button :disabled="true" @change="changeOverTime()" class="togglePeriodOfTime"
                                   :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true"
                                   :value="booking.overPeriodofTime"/>
                    Over a period of time
                  </div>
                  <datepicker
                      format="dd/MM/yyyy"
                      :typeable="true"
                      class="w-50 accient-date"
                      placeholder="dd/mm/yyyy"
                      :disabled="true"
                      v-model="booking.overPeriodofTime ? '' : booking.accidentDate"
                      @selected="selectAccientDate"
                      @input="changeAccientDate"></datepicker>
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Commencement Date</div>
              <div class="box-col box-col-right box-col-center">
                <datepicker
                    class="w-50"
                    :disabled="true"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    placeholder="dd/mm/yyyy"
                    v-model="booking.commencementDate"
                ></datepicker>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center pl-3">
                <vue-tags-input
                  v-model="bodyAreaTag"
                  :tags="bodyAreaTags"
                  :disabled="booking.bookingStatus >= 5"
                  placeholder="Please choose"
                  @before-deleting-tag="deleteBodyArea"
                  @focus="openDigram" />
              </div>
              <div v-if="booking.bookingStatus < 5" @click="openBodyDiagram" class="center-item">
                <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"/>
              </div>
              <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal"/>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input :disabled="booking.bookingStatus >= 5"
                  v-model="otherBodyAreaTag"
                  :tags="otherBodyAreaTags"
                  placeholder="Add Other Body Areas"
                  @tags-changed="updateOtherBodyArea" />
              </div>
              <div class="box-col box-col-right w-auto">
                &nbsp;
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                    :disabled="booking.bookingStatus >= 5"
                  ref="conditions"
                  v-model="personConditionTag"
                  :tags="personConditionTags"
                  placeholder="Add Conditions"
                  :add-only-from-autocomplete="true"
                  @before-deleting-tag="deletePersonCondition"
                  :autocomplete-items="filteredPersonConditionsItems"
                  @tags-changed="updateConditions" />
              </div>
              <div class="box-col box-col-right w-auto">
                &nbsp;
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Report to</div>
              <div class="box-col box-col-right">
                <el-select @change="selectedSendTo" placeholder="Select a Client"
                           :disabled="booking.bookingStatus  >= 5" class="w-100" v-model="booking.reportTo" filterable
                           clearable>
                  <el-option v-for="contact in originalClientContacts" :key="contact.value" :value="contact.value"
                             :label="contact.name">{{ contact.name }}
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="showSendReportToOther" class="box-row">
              <div class="box-col box-col-left left-label">Send report Email</div>
              <div class="box-col box-col-right">
                <input :disabled="booking.bookingStatus >= 5" class="textbox" maxlength="100" type="text"
                       placeholder="Send report to this email address" v-model="reportToText"/>
                <div v-if="hasError && !validateSendEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Bill to</div>
              <div class="box-col box-col-right">
                <el-select @change="selectedBillTo" placeholder="Select a Client"
                           :disabled="booking.bookingStatus  >= 5" class="w-100" v-model="booking.billTo" filterable
                           clearable>
                  <el-option v-for="contact in originalClientContacts" :key="contact.value" :value="contact.value"
                             :label="contact.name">{{ contact.name }}
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="showBillToEmail" class="box-row">
              <div class="box-col box-col-left left-label">Bill to Email</div>
              <div class="box-col box-col-right">
                <input :disabled="booking.bookingStatus  >= 5" class="textbox" type="text"
                       placeholder="Bill to this email address" v-model="billToText"/>
                <div v-if="hasError && !validateBillEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Interpreter Required?</div>
              <div class="box-col box-col-right d-flex align-items-center">
                <toggle-button :disabled="booking.bookingStatus >= 5" @change="onChartToggleChange(1)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.interpreterRequired"/>
                <el-checkbox :disabled="booking.bookingStatus >= 5" @change="changeFilter" v-if="booking.interpreterRequired" class="ms-3" v-model="booking.OrganizeRequired" size="large">Red Health to organize</el-checkbox>
              </div>
            </div>
            <div class="box-row" v-if="booking.interpreterRequired && !booking.OrganizeRequired">
              <div class="box-col box-col-left">Interpreter Details</div>
              <div class="box-col box-col-right">
                <input :disabled="booking.bookingStatus >= 5" class="textbox" type="text" placeholder="Interpreter Details" v-model="booking.interpreterDetails" />
                <div class="text-danger" v-if="hasError && !validateInterpreter">
                  Please input interpreter details
                </div>
              </div>
            </div>
            <div class="box-row">
              <div  class="box-col box-col-left">Is a Plus Service required for this booking? <img class="help ml-2" data-html="true" rel="tooltip" data-toggle="tooltip" data-placement="right"
                title="A combined service conducted by the examiner and an OT. It is a modified FCE known as FCElite which provides further robustness to the examiner's report.</br>"
                src="@/assets/images/infoicon.svg" style="width: 25px;" /></div>
              <div class="box-col box-col-right">
                <toggle-button :disabled="booking.bookingStatus >= 5" @change="onChartToggleChange(2)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.otfceRequired"/>
              </div>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading px-5  notes-heading tab-selected" @click="openNotes">Notes</div>
            <div class="tab-heading px-5 files-heading" @click="openFiles">Files</div>
            <div class="col-space-between is-icon right-float-element">
              <v-btn class="add-icon mr-3" dark @click="openAddNoteModal"
                     v-if="showAddNote && booking.bookingStatus !== 7 && booking.bookingStatus !== 6">Add New
              </v-btn>
              <v-btn class="add-icon mr-3" dark @click="openFilesBox"
                     v-if="!showAddNote && booking.bookingStatus !== 6">Add New
              </v-btn>
              <AddNoteModal ref="addNoteModal" :bookingProps="booking"/>
            </div>
          </div>
          <div class="box-body notes-content hidden-element">
            <div class="box-row" v-for="(item, index) in bookingNoteAndActions">
              <div class="box-col box-col-left" style="width: 12%">{{ item.userName }}</div>
              <div class="box-col box-col-left" style="width: 13%">
                {{ formatDate(item.dateTime, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss") }}
              </div>
              <div v-if="item.type == 'note'" @click="editNote(index)" style="width: width: 75%"
                   class="box-col box-col-right box-col-long col-center-content text-break">
                {{ item.note }}
              </div>
              <div v-else class="box-col box-col-right box-col-long col-center-content" style="width: 75%">
                <span class="text-bold">Requested Action:</span> {{ item.note }}
              </div>
              <div v-if="item.type == 'note' && item.note.length > 0 && booking.bookingStatus !== 6"
                   class="col col is-icon center-title" style="max-width: 10%;" @click="deleteNote(item.id, index)"><img
                  class="right-align" :class="item.userId != user.id ? 'disabled-element' : ''"
                  src="@/assets/images/delete_icon.svg"/></div>
              <div v-if="item.type == 'action' && item.userId == user.id && booking.bookingStatus !== 6"
                   class="col col is-icon center-title" style="max-width: 10%;" @click="editAction(item.id, index)"><img
                  class="right-align" src="@/assets/images/edit_icon.svg"/></div>
            </div>
          </div>
          <div class="box-body files-content hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                  <tr>
                    <th style="width:15%" class="long-text">
                      File Type
                      <img v-if="listParam.SortFlag === 1" class="sort-icon"
                           :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)"/>
                      <img v-else-if="listParam.SortFlag === 2" class="sort-icon"
                           :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                           @click="sortParam(1)"/>
                    </th>
                    <th style="width:30%" class="long-text">
                      File Name
                      <img v-if="listParam.SortFlag === 3" class="sort-icon"
                           :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)"/>
                      <img v-else-if="listParam.SortFlag === 4" class="sort-icon"
                           :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                           @click="sortParam(3)"/>
                    </th>
                    <th style="width:15%" class="long-text">
                      Uploaded By
                      <img v-if="listParam.SortFlag === 5" class="sort-icon"
                           :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)"/>
                      <img v-else-if="listParam.SortFlag === 6" class="sort-icon"
                           :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                           @click="sortParam(5)"/>
                    </th>
                    <th style="width:20%" class="long-text">
                      Date Uploaded
                      <img v-if="listParam.SortFlag === 7" class="sort-icon"
                           :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)"/>
                      <img v-else-if="listParam.SortFlag === 8" class="sort-icon"
                           :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                           @click="sortParam(7)"/>
                    </th>
                    <th style="width:10%" class="long-text">
                      Status
                      <img v-if="listParam.SortFlag === 9" class="sort-icon"
                           :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)"/>
                      <img v-else-if="listParam.SortFlag === 10" class="sort-icon"
                           :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)"/>
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')"
                           @click="sortParam(9)"/>
                    </th>
                    <th style="width:5%" class="icon-col">
                      <div class="icon-group">&nbsp;</div>
                    </th>
                    <th style="width:5%" class="icon-col">
                      <div class="icon-group">&nbsp;</div>
                    </th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody :key="fileKey">
                  <tr v-for="(file, index) in booking.bookingFiles" :key="index">
                    <td style="width:15%" class="long-text">
                      <select v-model="file.FileTypeID" v-if="file.UploadedBy === user.id" class="w-100 textbox"
                              @change="updateFileType(file.BookingFileID, index)">
                        <option value="" hidden>Select File Type</option>
                        <option v-for="fileType in fileTypes" :value="fileType.id">{{ fileType.name }}</option>
                      </select>
                      <span style="padding-left: 10px;" v-else>{{ file.FileTypeName }}</span>
                    </td>
                    <td style="width:30%" title="download" @click="getFileUrl(file)" class="long-text cursor-pointer">
                      <el-tooltip  effect="dark" :content="file.FileName">
                        <span> {{ file.FileName }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width:15%" class="long-text">{{ decodeURIComponent(file.UserName) }}</td>
                    <td style="width:20%" class="long-text">{{ formatDate(file.DateUploaded, 'MM/DD/YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss.ssss') }}</td>
                    <td style="width:10%" class="long-text">
                      <span
                          style="padding-left: 10px;">{{
                          file.FileStatus == 2 ? 'Complete' : getFileStatusNameById(file.FileStatus)
                        }}</span>
                    </td>
                    <td style="width:5%; vertical-align: middle;" class="long-text is-icon">
                      <div class="icon-group d-flex justify-content-center"><img
                          v-if="booking.bookingStatus !== 6 && file.FileStatus < 2 && file.UploadedBy === user.id && (file.FileTypeName ==='Brief' || file.FileTypeName ==='Other' || file.FileTypeName ==='Letter of Instruction')"
                          @click="deleteFile(file.BookingFileID, index, file.UploadedBy)"
                          src="@/assets/images/delete_icon.svg"/></div>
                    </td>
                    <td style="width:5%; vertical-align: middle;" class="long-text">

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV"/>
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex flex-column align-items-center justify-content-center">
                <div>Drop files in this area or <span style="text-decoration:underline"
                                                      class="is-icon">click here</span> to upload files.
                </div>
                <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
              </label>
              <FileTypeModal ref="fileTypeModal"/>
            </div>
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="alert-warning alert confirmation hidden-element">
          <div class="icon-group center-element">
            <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Ok</v-btn>
          </div>
          <b>Warning:</b> {{ errorMessage }}
        </div>
        <div class="box-row item-right button-action" v-if="booking.bookingStatus != 6">
          <div style="padding-right:20px" :class="!disableEditing ? 'disabled-element' : ''">
            <v-btn dark @click="tapCancel">Cancel</v-btn>
          </div>
          <div style="padding-right:20px" v-if="booking.bookingStatus != 1"
               :class="!disableEditing ? 'disabled-element' : ''">
            <v-btn dark @click="tapReschedule">Reschedule</v-btn>
          </div>
          <div style="padding-right:20px" v-if="booking.bookingStatus == 1">
            <v-btn dark @click="tapDelete">Delete</v-btn>
          </div>
          <div style="padding-right:20px">
            <v-btn dark @click="tapRequestAction" v-if="booking.bookingStatus != 7">Request Action</v-btn>
          </div>
          <div :class="!hasChange ? 'disabled-element' : ''">
            <v-btn dark @click="tapUpdateBooking" v-if="booking.bookingStatus != 7">Update</v-btn>
          </div>
          <CancelBookingModal ref="cancelBookingModal"/>
          <RequestActionModal ref="requestActionModal"/>
        </div>
      </div>
    </div>
    <Chat/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import CaseMenu from '@/components/CaseMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import CancelBookingModal from '@/components/CancelBookingModal'
import RequestActionModal from '@/components/RequestActionModal'
import AddNoteModal from '@/components/AddNoteModal'
import ProfileReadMoreModal from '@/components/ProfileReadMoreModal'
import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'
import FileTypeModal from '@/components/FileTypeModal'

export default {
  name: 'EditBooking',
  components: {
    Navigation,
    CaseMenu,
    Header,
    Chat,
    AddNoteModal,
    CancelBookingModal,
    RequestActionModal,
    ProfileReadMoreModal,
    AddBodyDiagramModal,
    FileTypeModal
  },
  data() {
    return {
      listParam: {
        SortFlag: 0,
      },
      curSpecialty: '',
      bookingTypes: [],
      claimTypes: [],
      specialist: [],
      clientContacts: [],
      client: [],
      bookingActions: [],
      filelist: [],
      bookingLog: [],
      locations: [],
      bookingNoteAndActions: [],
      validOnBehalfOf: true,
      bodyAreaTag: "",
      otherBodyAreaTag: "",
      personConditionTag: "",
      bodyAreas: [],
      bodyAreaTags: [],
      otherBodyAreaTags: [],
      originalOtherBodyAreaTags: [],
      deletedBodyTags: [],
      personConditions: [],
      personConditionTags: [],
      originalPersonConditionTags: [],
      deletedConditionTags: [],
      addedBodyArea: [],
      addedConditions: [],
      states: [],
      billToText: '',
      reportToText: '',
      subTitle: '',
      toggleInterpreter: '',
      successMessage: '',
      errorMessage: '',
      hasError: false,
      isEdit: true,
      showContent: false,
      editBookingStatus: false,
      disableEditing: true,
      loaded: false,
      fileKey: 0,
      showError: false,
      showAddNote: true,
      callBookingAction: false,
      togglePeriodOfTime: false,
      isUpdated: false,
      bookingDate: moment().format('DD/MM/YYYY'),
      isChangedAttendance: false,
      originalStatus: '',
      fileStatus: utilities.getFileStatus(),
      fileTypeId: 0,
      fileTypes: [],
      pagination: utilities.initPagination(),
      booking: utilities.initBooking(),
      oldBooking: utilities.initBooking(),  // booking from API
      originalBooking: utilities.initBooking(), // original booking
      bookingStatus: utilities.getBookingStatuses(),
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      case: utilities.initCase(),
      showBillToEmail: false,
      showSendReportToOther: false,
      listContact: [],
      originalClientContacts: [],
      isSelected: false,
    }
  },
  watch: {
    booking: {
      handler(value) {
        if (this.loaded) {
          this.$store.dispatch("setNewObject", value)
          let originalObject = this.$store.getters["getOriginalObject"]
          originalObject.billingNotes = value.billingNotes
          originalObject.bookingNotes = value.bookingNotes
          originalObject.bookingFiles = value.bookingFiles
          this.$store.dispatch("setOriginalObject", originalObject)
        }
      },
      deep: true,
    },
  },
  computed: {
    showSupplementaryButton() {
      return moment().unix() > moment(this.booking.bookingDateTime).unix()
    },
    validateInterpreter() {
      if (this.booking.interpreterRequired && !this.booking.OrganizeRequired && !this.booking.interpreterDetails) {
        return false
      }
      return true
    },
    validateBillEmail() {
      if (this.showBillToEmail) {
        if (this.billToText != "" && !utilities.validateEmail(this.billToText)) {
          return false
        }
      }
      return true
    },

    validateSendEmail() {
      if (this.showSendReportToOther) {
        if (this.reportToText != "" && !utilities.validateEmail(this.reportToText)) {
          return false
        }
      }
      return true
    },

    validateState() {
      if ((this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7 || this.booking.bookingTypeId == 15) && this.booking.claimantStateDuringBooking == '') {
        return false
      }
      return true
    },

    hasChange() {
      var result = false
      // Format Accident Date after selected from the calendar
      if (typeof this.booking.accidentDate != 'string' && moment(this.booking.accidentDate, "YYYY-MM-DD").isValid()) {
        this.booking.accidentDate = moment(this.booking.accidentDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      }
      // Format Commencement Date after selected from the calendar
      if (typeof this.booking.commencementDate != 'string' && moment(this.booking.commencementDate, "YYYY-MM-DD").isValid()) {
        this.booking.commencementDate = moment(this.booking.commencementDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      }
      this.originalBooking.bookingNotes = _.cloneDeep(this.booking.bookingNotes)
      this.originalBooking.billingNotes = _.cloneDeep(this.booking.billingNotes)
      if (_.isEqual(this.originalBooking, this.booking)
          && _.isEqual(this.originalOtherBodyAreaTags, this.otherBodyAreaTags)
          && _.isEqual(this.originalPersonConditionTags, this.personConditionTags)) {
        result = false
      } else {
        result = true
      }
      return result
    },

    filteredBodyAreaItems() {
      return this.bodyAreas.filter(i => {
        return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1;
      })
    },

    filteredPersonConditionsItems() {
      return this.personConditions.filter(i => {
        return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1;
      })
    },
  },
  methods: {
    changeFilter(v) {
      if (v) {
        this.booking.interpreterDetails = ''
      }
    },
    sortParam(param = 7) {
      this.listParam.SortFlag = param
      switch (param) {
        case 1:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.FileTypeName >= a.FileTypeName) ? 1 : -1
          })
          break;
        case 2:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.FileTypeName >= b.FileTypeName) ? 1 : -1
          })
          break;
        case 3:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.FileName >= a.FileName) ? 1 : -1
          })
          break;
        case 4:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.FileName >= b.FileName) ? 1 : -1
          })
          break;
        case 5:
          this.booking.bookingFiles.sort((a, b) => {
            return (b.UserName >= a.UserName) ? 1 : -1
          })
          break;
        case 6:
          this.booking.bookingFiles.sort((a, b) => {
            return (a.UserName >= b.UserName) ? 1 : -1
          })
          break;
        case 7:
          this.booking.bookingFiles.sort((a, b) => {
            if(moment(a.DateUploaded).isAfter(moment(b.DateUploaded))) {
              return -1
            }
            return 1
          })
          break;
        case 8:
          this.booking.bookingFiles.sort((a, b) => {
            if(moment(a.DateUploaded).isBefore(moment(b.DateUploaded))) {
              return -1
            }
            return 1
          })
          break;
        case 9:
          this.booking.bookingFiles.sort((a, b) => {
            return b.FileStatus - a.FileStatus
          })
          break;
        case 10:
          this.booking.bookingFiles.sort((a, b) => {
            return a.FileStatus - b.FileStatus
          })
          break;
      }
    },
    getFileUrl(file) {
      const params = {
        fn: file.FileName,
        id: this.booking.id,
        VersionId: file.VersionId,
        filetype: "bf",
      }
      utilities.getDownloadFileUrlNew(params).then(res => {
        let a = document.createElement('a');
        let fileName = file.fileName
        a.target = '_blank'
        a.download = fileName;
        a.href = res
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(res);
        document.body.removeChild(a);
      })
    },
    toggleAttendance() {
      this.isChangedAttendance = true
    },

    getInitials(specialist) {
      return utilities.getInitials(specialist.firstName, specialist.lastName)
    },

    checkBookingObjectisUpdated() {
      if (_.isEqual(this.originalBooking, this.booking)) {
        this.isUpdated = false
      } else {
        this.isUpdated = true
      }
    },

    getOriginalBooking() {
      if (!this.isUpdated) {
        this.originalBooking = _.cloneDeep(this.booking)
        this.originalOtherBodyAreaTags = _.cloneDeep(this.otherBodyAreaTags)
        this.originalPersonConditionTags = _.cloneDeep(this.personConditionTags)
      }
    },

    tapSupplementaryService() {
      this.$router.push({name: 'supplementary-service-request', query: {id: this.booking.id}}).catch((err) => {
      })
    },

    changeOverTime() {
      this.booking.overPeriodofTime = !this.booking.overPeriodofTime
      if (this.booking.overPeriodofTime) {
        this.booking.accidentDate = ''
      }
      this.isValidAccidentDate = this.validateAccidentDate()
    },

    validateAccidentDate() {
      if ($(".accient-date input").val().length > 0) {
        if ((this.booking.accidentDate == '' || !moment($(".accient-date input").val(), "DD/MM/YYYY").isValid()) && !this.overTime) {
          return false
        }
      }
      return true
    },

    updateConditions(newTags) {
      if (this.$refs.conditions.newTag.length == 0) newTags.pop()
      this.personConditionTags = newTags
      this.booking.personConditions = this.personConditionTags
    },

    updateOtherBodyArea(newTags) {
      this.otherBodyAreaTags = newTags
      this.booking.bodyAreaOther = this.otherBodyAreaTags
    },

    selectAccientDate() {
      this.booking.overPeriodofTime = false
    },

    changeAccientDate() {
      setTimeout(() => {
        this.isValidAccidentDate = this.validateAccidentDate()
      }, 200)
    },

    deleteBodyArea(obj) {
      if (this.booking.bookingStatus >= 5) return
      this.openDigram()
      // this.deletedBodyTags.push(obj.tag)
      // if (this.deletedBodyTags.length > 0) {
      //   this.deletedBodyTags.forEach((item, i) => {
      //     var bodyArea = this.bodyAreas.filter((y) => y.text == item.text)[0]
      //     if (bodyArea) {
      //       bodyArea = {
      //         BodyAreaID: bodyArea.value,
      //         BookingBodyAreaID: bodyArea.value
      //       }
      //       bodyAreaObjects.push(bodyArea)
      //     }
      //   });
      // }
      // var params = {
      //   BookingId: this.booking.id,
      //   BodyAreaObject: bodyAreaObjects
      // }
      //
      // await utilities.putBookingBodyAreaPersonCondition(params)
      // this.bodyAreaTags.splice(obj.index, 1);
    },

    deletePersonCondition(obj) {
      this.deletedConditionTags.push(obj.tag)
      this.personConditionTags.splice(obj.index, 1);
      this.booking.personConditions = this.personConditionTags
    },

    openDigram() {
      if (this.booking.bookingStatus >= 5) return
      this.openBodyDiagram()
    },

    openBodyDiagram() {
      this.$refs.addBodyDiagramModal.handleModal()
      this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
      this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
      this.$refs.addBodyDiagramModal.handleClick()
    },

    async setBodyArea(newBodyTags) {
      this.$store.dispatch("loading/setLoadComponent", true)
      await this.callUpdateBodyAreaAPI(newBodyTags)
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    deleteBodyAreaItems(items) {
      items.map((x) => {
        this.deletedBodyTags.push(x.tag)
      })
    },

    async callUpdateBodyAreaAPI(newBodyTags) {
      var bodyAreaObjects = []
      var bodyArea = {}

      // Pass deleted body Area object
      if (this.deletedBodyTags.length > 0) {
        this.deletedBodyTags.map((x) => {
          var temp = this.booking.bodyAreas.find((y) => y.bodyArea == x.text)
          if (temp) {
            bodyArea = {
              BodyAreaID: temp.bodyAreaId,
              BookingBodyAreaID: temp.bookingBodyAreaId
            }
            bodyAreaObjects.push(bodyArea)
          }
        })
      }

      // Pass new body Area object
      if (newBodyTags.length > 0) {
        newBodyTags.map((x) => {
          var temp = this.booking.bodyAreas.find((y) => y.bodyArea == x.text)
          if (temp == undefined) {
            bodyArea = {
              BodyAreaID: x.value
            }
            bodyAreaObjects.push(bodyArea)
          }
        })
      }

      if (bodyAreaObjects.length > 0) {
        var params = {
          BookingId: this.booking.id,
          BodyAreaObject: bodyAreaObjects,
          UserID: this.user.id,
          Username: this.user.name,
        }
        var result = await utilities.putBookingBodyAreaPersonCondition(params)
        if (result) {
          this.bodyAreaTags = _.cloneDeep(newBodyTags)
          // Get body Areas after updating
          var booking = await utilities.getBookingById(this.booking.id)
          this.setclaimantConfirmed()
          if (booking) this.booking.bodyAreas = _.cloneDeep(booking.bodyAreas)
          utilities.showSuccessMessage("Booking details updated.")
        }
        this.deletedBodyTags = []
        this.getOriginalBooking()
      }
    },

    filterReportTo(text) {
      if (text.length > 0) {
        this.listClients = this.originalClientContacts.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      }
    },

    selectedBillTo(item) {
      if (item === 'Other') {
        this.billToText = ''
        this.showBillToEmail = true
      } else {
        this.showBillToEmail = false
      }
    },

    selectedSendTo(item) {
      if (item === 'Other') {
        this.reportToText = ""
        this.showSendReportToOther = true
      } else {
        this.showSendReportToOther = false
      }
    },
    getFileStatusNameById(id) {
      if(id == 2) {
        return 'Complete'
      } else if (id == 3) {
        return 'For Review'
      }
      if (id != 3) {
        return this.fileStatus.find((x) => x.id == id).name
      }
      return ''
    },

    tapButtonConfirmation(states) {
      utilities.hideAlert('.confirmation')
    },

    async checkBookingAvailability(event) {
      this.showError = false
      var date = moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD')
      var todayDate = moment().format('YYYY-MM-DD')
      if (moment(date, "YYYY-MM-DD").isBefore(todayDate)) {
        this.errorMessage = 'The selected date and time is no longer available.  Please contact Red Health.'
        this.showError = true
      }
    },

    async finalizeFile(bookingFileId, index) {
      var file = this.booking.bookingFiles[index]
      this.$store.dispatch('loading/setLoadComponent', true)
      var params = {
        BookingFileID: bookingFileId,
        FileStatus: file.fileStatus,
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        FileStatusName: this.fileStatus.find((x) => x.id == file.fileStatus).name,
        FileName: file.fileName,
        BookingDateTime: (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
        SpecialistName: this.booking.specialistFullName,
        CaseOwnerName: this.getCaseOwnerName(),
        ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
        BookingTypeName: this.booking.bookingTypeName,
        LocationName: this.getLocationName(this.booking.locationId),
        CurrentDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
      await utilities.putBookingFileDetails(params)
      this.getOriginalBooking()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    async updateFileType(bookingFileId, index) {
      var file = this.booking.bookingFiles[index]
      this.$store.dispatch('loading/setLoadComponent', true)
      var params = {
        BookingFileID: bookingFileId,
        FileTypeID: file.FileTypeId,
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        FileTypeName: this.fileTypes.find((x) => x.id == file.FileTypeID).name,
        FileName: file.FileName,
        BookingDateTime: (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
        SpecialistName: this.booking.specialistFullName,
        CaseOwnerName: this.getCaseOwnerName(),
        ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
        BookingTypeName: this.booking.bookingTypeName,
        LocationName: this.getLocationName(this.booking.locationId),
        CurrentDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
      }
      await utilities.putBookingFileDetails(params)
      this.getOriginalBooking()
      this.$store.dispatch('loading/setLoadComponent', false)
    },

    tapReadMore(index) {
      this.$refs.profileReadMoreModal.loadData(index)
      this.$refs.profileReadMoreModal.handleModal()
    },

    getSpecialistInfo(locationId, locations) {
      var text = ""
      if (this.specialist.qualificationList) {
        text = this.specialist.qualificationList
      }

      // if (locationId) {
      //   var value = locations.find((x) => x.id == locationId).shortLocation
      //   if (value) {
      //     if (text.length == 0) {
      //       return value
      //     } else {
      //       return text + ", " + value
      //     }
      //   }
      // }
      return text
    },

    onChartToggleChange(index) {
      switch (index) {
        case 0:
          this.booking.private = !this.booking.private
          break;
        case 1:
          this.booking.interpreterRequired = !this.booking.interpreterRequired
          this.booking.OrganizeRequired = false
          this.callBookingAction = this.booking.interpreterRequired
          if (!this.booking.interpreterRequired) {
            this.booking.interpreterDetails = ""
          }
          break;
        case 2:
          this.booking.otfceRequired = !this.booking.otfceRequired
          break;
        default:
      }
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date != '') {
        if (format == 'MMM, YYYY hh:mm A' && this.booking.bookingStatus == 16) {
          format = 'MMM, YYYY'
        }
        return utilities.formatDate(date, format, currentFormat)
      }
      return ''
    },

    onChangeBookingType(e) {

    },

    getFile(filePath, fileName) {
      utilities.getFile(filePath, fileName)
    },

    onChangeContact(event, status) {
    },

    async bookingCancel() {
      this.booking.bookingStatus = 6
      this.editBookingStatus = false
      this.successMessage = 'The booking has now been cancelled.'
      utilities.showAlert(".alert-success")
      this.bookingStatus = utilities.getBookingStatuses(),
          await this.loadData()
    },

    async actionConfirmation(isNew = true) {
      if (isNew) {
        this.successMessage = "Your update has been saved."
        utilities.showAlert(".alert-success")
      }
      this.bookingActions = await utilities.getBookingActions(this.booking.id)
      this.setBookingNoteAndAction()
    },

    async tapUpdateBooking() {
      if (!this.hasChange) {
        return
      }

      if (!this.validateState) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return
      }

      if (!this.validateInterpreter) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return
      }

      if (this.showError) {
        this.showWarning()
        this.booking.bookingStatus = this.originalStatus
        return
      }

      var otherBodyArea = ""
      this.otherBodyAreaTags.map((x) => {
        if (otherBodyArea == "") {
          otherBodyArea = x.text
        } else {
          otherBodyArea = otherBodyArea + "," + x.text
        }
      })


      var zohoId = ''
      if (this.booking.zohoLink != '') {
        zohoId = this.booking.zohoLink.split("/");
        zohoId = zohoId[zohoId.length - 1];
      }

      var params = {
        BookingID: this.booking.id,
        BookingStatus: this.booking.bookingStatus,
        BookingStatusName: utilities.getBookingStatuses().find((x) => x.id === this.booking.bookingStatus).name,
        BillTo: this.showBillToEmail ? this.billToText : this.booking.billTo,
        ReportTo: this.showSendReportToOther ? this.reportToText : this.booking.reportTo,
        ReportToEmailAddress: this.booking.reportToEmailAddress,
        BookingTypeID: this.booking.bookingTypeId,
        BookingTypeName: this.getBookingTypeName(this.booking.bookingTypeId),
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        CaseID: this.booking.caseId,
        InterpreterRequired: utilities.convertBoolToNum(this.booking.interpreterRequired),
        OrganizeRequired: utilities.convertBoolToNum(this.booking.OrganizeRequired),
        InterpreterDetails: this.booking.interpreterDetails,
        OTFCERequired: utilities.convertBoolToNum(this.booking.otfceRequired),
        // Private: utilities.convertBoolToNum(this.booking.private),
        OnBehalfOf: this.booking.onBehalfOf,
        CaseReference: this.booking.caseReference,
        CallClientAfterAppointment: this.booking.callClientAfterAppointment,
        PreferredGivenName: this.booking.PreferredGivenName,
        Salutation: this.booking.Salutation,
        UserID: this.user.id,
        Username: this.user.name,
        BookingDateTime: (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
        BookingEndTime: window.moment(this.booking.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss'),
        SpecialistZohoLink: this.specialist.zohoLink,
        ZohoBookingDateTime: moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format(),
        ZohoLink: zohoId,
        AvailabilityID: this.booking.specialistAvailabilityId,
        BookingStatusChanged: 0,
        LocationName: this.getFullLocation(this.booking.locationId),
        StateName: this.getLocationState(this.booking.locationId),
        AccidentDate: this.booking.accidentDate ? moment(this.booking.accidentDate, "YYYY-MM-DD").format('YYYY-MM-DD') : "",
        OverPeriodofTime: utilities.convertNumToBool(this.booking.overPeriodofTime),
        BodyAreaOther: otherBodyArea,
        ZohoCaseID: this.case.zohoCaseID,
        ClientZohoLink: this.client.zohoLink,
        Specialty: this.specialist.qualificationList,
      }
      // Send this param only if changing booking status from Tentative to something else and zohoLink is empty
      if (this.originalStatus === 2 && this.originalStatus !== this.booking.bookingStatus && (this.booking.zohoLink === '' || this.booking.zohoLink === '0')) {
        params.OldBookingStatusTentative = 1
        var contact = this.clientContacts.find((x) => x.userId == this.booking.caseOwner)
        params.ClientContactZohoName = contact != undefined ? contact.fullName : ''
        params.ZohoObject = {
          ClientContactZohoLink: contact != undefined ? contact.zohoClientContactId : '',
          ClaimantSurname: this.booking.claimantSurname,
          ClaimantFirstName: this.booking.claimantFirstName,
          ZohoDateCreated: moment().format(),
        }
      }

      if (this.isChangedAttendance) {
        params.ClaimantConfirmed = this.booking.claimantConfirmed == "1" ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00'
      }

      if (this.originalStatus != this.booking.bookingStatus) {
        params.BookingStatusChanged = 1
      }

      if (this.booking.paperworkDue) {
        params.PaperworkDue = moment(this.booking.paperworkDue, "YYYY-MM-DD").format('YYYY-MM-DD')
      } else {
        params.PaperworkDue = ''
      }

      //Pass extra data into the zoho
      params.ClientCategory = this.client.clientCategory
      params.ClientState = this.client.state
      params.CCEmail = this.booking.clientContacts[0].NotificationEmailAddress || this.booking.clientContacts[0].email


      // 4202 - Appointments by Video – Handling Location & Links
      var location = this.locations.find((x) => x.id == this.booking.locationId)
      var state = location ? location.state : ""
      params.ClaimantStateDuringBooking = (this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7) ? this.booking.claimantStateDuringBooking : state
      params.ClaimantBookingDateTime = this.booking.claimantBookingDateTime

      this.isUpdated = false
      if (this.booking.bookingStatus == 8) {
        params.BookingStatusName = 'Booked'
      }
      var callback = (response) => {
        this.isChangedAttendance = false
        this.updatePersonConditions()
        this.$store.dispatch("setOriginalObject", null)
        this.$store.dispatch("setNewObject", null)

        this.successMessage = "Your update has been saved."
        utilities.showAlert(".alert-success")
        this.originalBooking = _.cloneDeep(this.booking)
        this.checkBookingObjectisUpdated()
        this.getOriginalBooking()
      }
      this.$store.dispatch("loading/setLoadComponent", true)
      await utilities.putBooking(params, callback)

      if (this.callBookingAction != this.oldBooking.interpreterRequired && this.callBookingAction) {
        var parem = {
          BookingID: this.booking.id,
          DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
          RequestedBy: this.user.id,
          BookingActionRequestStatus: 1,
          RequestedAction: "Interpreter Required",
          UserID: this.user.id,
          Username: this.user.name,
        }
        var callback = (response) => {
        }
        utilities.postBookingAction(parem, callback)
      }
      this.oldBooking = _.cloneDeep(this.booking)
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    getLocationState(id) {
      if (id) {
        return this.locations.find((x) => x.id == id).state
      }
    },

    async updatePersonConditions() {
      var conditionObject = []
      var condition = {}
      if (this.personConditionTags.length > 0) {
        this.personConditionTags.map((x) => {
          var temp = this.addedConditions.filter((y) => y.name == x.text)
          if (temp == undefined || temp.length == 0) {
            condition = {
              PersonConditionID: x.value
            }
            conditionObject.push(condition)
          }
        })
      }

      //Pass deleted PersonCondition object
      if (this.deletedConditionTags.length > 0) {
        this.deletedConditionTags.forEach((item, i) => {
          this.addedConditions.forEach((added, i) => {
            if (item.text == added.name) {
              var condition = this.personConditions.filter((y) => y.text == item.text)[0]
              condition = {
                PersonConditionID: condition.value,
                BookingPersonConditionID: added.bookingPersonConditionId
              }
              conditionObject.push(condition)
            }
          });
        });
      }

      if (conditionObject.length > 0) {
        var params = {
          BookingId: this.booking.id,
          PersonConditionObject: conditionObject,
          UserID: this.user.id,
          Username: this.user.name,
        }
        await utilities.putBookingBodyAreaPersonCondition(params)
        this.deletedBodyTags = []
      }
    },

    editNote(index) {
      if (this.booking.bookingStatus === 6) return
      var item = this.bookingNoteAndActions[index]
      var note = this.booking.bookingNotes.find(x => x.id === item.id)
      if (item.userId === this.user.id) {
        this.$refs.addNoteModal.isEditClient = true
        this.$refs.addNoteModal.internalOnly = 1
        this.$refs.addNoteModal.editCurrentNote(note, false)
        this.$refs.addNoteModal.handleModal()
      }
    },

    editAction(id, index) {
      var item = this.bookingNoteAndActions[index]
      var action = this.bookingActions.find(x => x.id == item.id)
      this.$refs.requestActionModal.editAction(action)
      this.$refs.requestActionModal.handleModal()
    },

    async deleteFile(fileId, index, uploadedBy) {
      var file = this.booking.bookingFiles[index]
      this.$confirm({
        content: 'Are you sure you want to delete this file?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            userId: this.user.id,
            userName: this.user.name,
            id: fileId,
          }
          var params2 = {
            BookingID: this.booking.id,
            FileName: file.FileName,
            BookingDateTime: (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
            SpecialistName: this.booking.specialistFullName,
            CaseOwnerName: this.getCaseOwnerName(),
            ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
            BookingTypeName: this.booking.bookingTypeName,
            LocationName: this.getLocationName(this.booking.locationId),
            CurrentDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
            UserID: this.user.id,
            Username: this.user.name,
            IsDeleted: 1
          }
          var result = await utilities.deleteBookingFileById(params, params2)
          if (result) {
            this.booking.bookingFiles.splice(index, 1)
            this.getOriginalBooking()
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    openNotes() {
      this.showAddNote = true
      $('.notes-content').show()
      $('.tab-selected').removeClass('tab-selected')
      $('.notes-heading').addClass('tab-selected')
      $('.files-content').hide()
    },

    openFiles() {
      this.showAddNote = false
      $('.notes-content').hide()
      $('.tab-selected').removeClass('tab-selected')
      $('.files-heading').addClass('tab-selected')
      $('.files-content').show()
    },

    openAddNoteModal(e) {
      this.checkBookingObjectisUpdated()
      this.$refs.addNoteModal.addNewNote()
      this.$refs.addNoteModal.handleModal()
    },

    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },

    // addFileIntoBooking(file) {
    //   var newFile = {
    //     bookingFileId: 66,
    //     fileName: ,
    //     filePath: ,
    //     fileStatus: ,
    //     fileTypeId ,
    //     fileTypeName,
    //     uploadedBy:
    //   }
    //   this.booking.bookingFiles.push(newFile)
    // }

    openFileTypeModal() {
      this.$refs.fileTypeModal.reset()
      this.$refs.fileTypeModal.fileTypes = this.fileTypes
      this.$refs.fileTypeModal.handleModal()
    },

    clearFile() {
      $("#assetsFieldHandle").val(null)
    },

    onChange() {
      this.openFileTypeModal()
    },

    async uploadFile() {
      if (this.fileTypeId > 0) {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.checkBookingObjectisUpdated()
        this.filelist = [...this.$refs.file.files]
        var allFileNames = ''
        if (this.filelist.length > 0) {
          await Promise.all(this.filelist.map(async (x) => {
            if(this.booking.id != '') {
              try {
                var fileName = x.name
                if (allFileNames.length == 0) {
                  allFileNames = fileName
                } else {
                  allFileNames = allFileNames + ', ' + fileName
                }
                const result = await utilities.postPreSign({
                  ft: x.type ? x.type : 'application/octet-stream',
                  fn: fileName,
                  id: this.booking.id,
                  FileStatus: 0,
                  FileTypeID: this.fileTypeId,
                })
                let postData = new FormData()
                for (let key in result.fields) {
                  postData.append(key, result.fields[key]);
                }
                postData.append('file', x)
                const r = await utilities.postImage(result.url, postData)
                if(r) {
                  await utilities.postFileRecord([{
                        BookingID: this.booking.id,
                        DateUploaded:window.moment().format('YYYY-MM-DD HH:mm:ss'),
                        FileName: x.name,
                        FileStatus: 0,
                        FileTypeID: this.fileTypeId,
                        MimeType: x.type ? x.type : 'application/octet-stream',
                        UploadedBy: this.user.id,
                        UploadedByName: this.user.name
                      }]
                  )
                }
                if (this.filelist.length > 0) {
                  this.booking.bookingFiles = await utilities.getBookingFileNew({bookingID: this.booking.id, PageNum: 1, PageSize: 999})
                  this.sortParam(this.listParam.SortFlag)
                  this.showClientsFiles()
                  this.checkBookingObjectisUpdated()
                  this.getOriginalBooking()
                  this.fileKey += 1
                }
              } catch (error) {
                utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
              }
            }
          }))
        }

        this.$store.dispatch('loading/setLoadComponent', false)
      }
    },

    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains('bg-green-300')) {
      //   event.currentTarget.classList.remove('bg-gray-100')
      //   event.currentTarget.classList.add('bg-green-300')
      // }
      event.currentTarget.classList.add('dark-border-hover')
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100')
      // event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    tapCancel() {
      if (!this.disableEditing) {
        return
      }
      var timeLeft = moment().diff(moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss"), 'hours');
      if (timeLeft < 48) {
        var showCancelMsg = false
        this.bookingLog.map(x => {
          if (x.logDetails.includes("(8)") || x.logDetails.includes("(9)")) {
            showCancelMsg = false
          } else {
            showCancelMsg = true
          }
        })
      }

      if (showCancelMsg) {
        this.$refs.cancelBookingModal.showRescheduleMsg()
      }
      this.$refs.cancelBookingModal.handleModal()
    },

    async tapDelete() {
      this.$confirm({
        content: 'Are you sure you want to delete this Booking?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            userID: this.user.id,
            userName: this.user.name,
            bookingId: this.booking.id,
            caseId: this.booking.caseId
          }
          var result = await utilities.deleteBooking(params)
          if (result) {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')

          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    tapReschedule() {
      if (!this.disableEditing) {
        return
      }
      this.$router.push({
        path: '/search-specialist',
        query: {id: this.booking.caseId, bookingId: this.booking.id}
      }).catch((err) => {
      })
    },

    tapRequestAction() {
      this.$refs.requestActionModal.handleModal()
    },

    getBookingTypeName(typeId) {
      var name = this.bookingTypes.find((x) => x.id === typeId).name
      this.booking.bookingTypeName = name
      return this.booking.bookingTypeName
    },

    async deleteNote(noteId, index) {
      var note = this.bookingNoteAndActions[index]
      if (note.userId != this.user.id) {
        return
      }
      this.$confirm({
        content: 'Are you sure you want to delete this note?',
        onConfirm: async () => {
          this.$store.dispatch('loading/setLoadComponent', true)
          var params = {
            UserID: this.user.id,
            Username: this.user.name,
            id: noteId,
            IsMainEdit: this.hasChange ? 1 : 0,
          }
          if (params.IsMainEdit === 0) {
            params.BookingDateTime = (this.booking.bookingDateTime !== "" && this.booking.bookingDateTime.indexOf('0000-00-00') < 0) ? window.moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss') : null,
            params.SpecialistName = this.booking.specialistFullName
            params.CaseOwnerName = this.getCaseOwnerName()
            params.ClientName = this.booking.clientName + ' - ' + this.booking.branchName
            params.BookingTypeName = this.booking.bookingTypeName
            params.LocationName = this.getLocationName(this.booking.locationId)
            params.IsAdmin = true
            params.BookingID = this.booking.id
            params.NoteDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
          }
          var result = await utilities.deleteBookingNoteById(params)
          if (result) {
            note.deleted = true
            var deleteNote = this.booking.bookingNotes.find((x) => x.id == note.id)
            deleteNote.deleted = true
            this.bookingNoteAndActions.splice(index, 1)
            this.getOriginalBooking()
          }
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      })
    },

    getLocationName(id) {
      if (id) {
        var value = this.locations.find((x) => x.id == id)
        if (value) {
          return value.shortLocation
        }
      }
    },

    getFullLocation(id) {
      if (id && this.locations.length > 0) {
        return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
      }
    },

    getFullLocationWithCountry(id) {
      if (id && this.locations.length > 0) {
        return this.locations.find((x) => x.id == id).fullLocation
      }
    },

    async setBookingNoteAndAction(params) {
      if (params && this.bookingNoteAndActions && this.bookingNoteAndActions.length > 0) {
        const booking = this.bookingNoteAndActions.find(x => x.id === params.BookingNoteID)
        booking.dateTime = params?.NoteDateTime
        booking.note = params?.Note
        return
      }
      var bookingNoteAndActions = []
      for (var i = 0; i < this.booking.bookingNotes.length; i++) {
        var item = this.booking.bookingNotes[i]
        if (!item.deleted) {
          var noteObject = {
            id: item.id,
            userName: item.userName,
            userId: item.userId,
            dateTime: item.noteDateTime,
            note: item.note,
            type: 'note'
          }
          bookingNoteAndActions.push(noteObject)
        }
      }

      for (var i = 0; i < this.bookingActions.length; i++) {
        var item = this.bookingActions[i]
        var noteObject = {
          id: item.id,
          userName: item.userName,
          userId: item.requestedBy,
          dateTime: item.dateRequested,
          note: item.requestedAction,
          type: 'action'
        }
        bookingNoteAndActions.push(noteObject)
      }
      bookingNoteAndActions.sort((a, b) => {
        return moment(a.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").isBefore(moment(b.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss")) ? 1 : -1
      })
      this.bookingNoteAndActions = bookingNoteAndActions
    },

    getCaseOwnerName() {
      if (this.booking == undefined || this.booking.bookingId == '') {
        return ''
      }

      if (this.booking.clientContacts.length > 0) {
        return this.booking.clientContacts[0].clientContactFullName
      }
    },

    showWarning() {
      $('html, body').animate({
        scrollTo: $(".button-action").offset()
      }, 500);
      utilities.showAlert('.confirmation', false)
    },

    showClientsFiles() {
      var files = []
      this.booking.bookingFiles.map((x) => {
        if (x.FileTypeName != 'Internal Document' && x.FileTypeName != 'Other' && x.FileTypeName != 'Report' && !x.IsDeleted) {
          files.push(_.cloneDeep(x))
        } else if (x.FileTypeName == 'Other' && x.UploadedBy == this.user.id && !x.IsDeleted) {
          files.push(_.cloneDeep(x))
        } else if (x.FileTypeName == 'Other' && x.FileStatus == 1 && x.UploadedBy != this.user.id && !x.IsDeleted) {
          files.push(_.cloneDeep(x))
        } else if (x.FileTypeName == 'Report' && x.FileStatus == 2 && !x.IsDeleted) {
          if (!this.booking.prepaymentRequired) {
            files.push(_.cloneDeep(x))
          } else if (this.booking.prepaymentRequired && this.booking.paid) {
            files.push(_.cloneDeep(x))
          }
        }
      })
      this.booking.bookingFiles = _.cloneDeep(files)
    },

    isDate(date) {
      return moment(date, "YYYY-MM-DD").isValid()
    },

    setclaimantConfirmed() {
      if (this.isDate(this.booking.claimantConfirmed)) {
        this.booking.claimantConfirmed = '1'
      } else {
        this.booking.claimantConfirmed = '2'
      }
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      var id = this.$route.query.id != undefined ? this.$route.query.id : ''
      var status = this.$route.query.status != undefined ? this.$route.query.status : ''

      if (id != '') {
        this.booking = await utilities.getBookingById(id)
        this.sortParam()
        this.setclaimantConfirmed()
        this.bookingDate = this.booking.bookingDateTime != "" ? moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD') : ""
        if (this.booking == undefined) {
          this.showContent = false
          return
        } else {
          this.showContent = true
        }

        if (moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").isSameOrAfter(moment())) {
          this.subTitle = "Reschedule, Request Action, Cancel or Request a Supplementary Service."
        } else {
          this.subTitle = "Request Action or Request a Supplementary Service."
        }

        this.oldBooking = _.cloneDeep(this.booking)

        if (this.booking.bodyAreas.length > 0) {
          this.addedBodyArea = _.cloneDeep(this.booking.bodyAreas)
          this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.booking.bodyAreas)
        }

        if (this.booking.bodyAreaOther.length > 0) {
          var items = this.booking.bodyAreaOther.split(',');
          this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
        }

        if (this.booking.personConditions.length > 0) {
          this.addedConditions = _.cloneDeep(this.booking.personConditions)
          this.personConditionTags = utilities.getAutoCompleteTags(this.booking.personConditions)
        }

        if (this.booking.reportToEmailAddress && this.booking.reportTo == '') {
          this.booking.reportTo = "Other"
        }
        this.specialist = await utilities.getSpecialistById(this.booking.specialistId)
        // Load profile photo
        if (this.specialist.photo !== '') {
          utilities.getProfileThumbnail(this.specialist.photo).then(r => {
            this.specialist.profileThumbnail = r
          })
        }

        if (this.booking.bookingStatus == 1 || this.booking.bookingStatus == 2) {
          var editBookingStatus = []
          this.editBookingStatus = true
          this.bookingStatus.map((x) => {
            if (x.id == 1 || x.id == 2 || x.id == 3) {
              editBookingStatus.push(x)
            }
          })
          this.bookingStatus = editBookingStatus
        } else if (this.booking.bookingStatus == 5 || this.booking.bookingStatus == 9 || this.booking.bookingStatus == 10 || this.booking.bookingStatus == 11 || this.booking.bookingStatus == 12 || this.booking.bookingStatus == 13 || this.booking.bookingStatus == 14 ||
            this.booking.bookingStatus == 15) {
          this.disableEditing = false
        }
        const apiArr = [utilities.getBookingLogsById(id), utilities.getBookingActions(id), utilities.getBookingFileNew({bookingID: id, PageNum: 1, PageSize: 999})]
        Promise.all(apiArr).then(r => {
          this.bookingLog = r[0]
          this.bookingActions = r[1]
          this.booking.bookingFiles = r[2]
          this.showClientsFiles()
        })
      }

      this.states = utilities.getStates()
      this.client = await utilities.getClientById(this.user.clientId)

      const apisArr = [utilities.getBookingTypes(), utilities.getClaimTypes(), utilities.getLocations(), utilities.getClientContacts(this.user.clientId), utilities.getBodyArea(), utilities.getPersonConditions(), utilities.getFileTypes(), utilities.getCaseByCaseId(this.booking.caseId)]
      const r = await Promise.all(apisArr)
      this.bookingTypes = r[0]
      this.claimTypes = r[1]
      this.locations = r[2]
      this.clientContacts = r[3]
      let bodyAreas = r[4]
      this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)

      let personConditions = r[5]
      this.personConditions = utilities.getAutoCompleteTags(personConditions)

      let types = r[6]
      this.case = r[7]
      //set Bill to and Report To data
      this.originalClientContacts = _.cloneDeep(utilities.getClientsDropdownAutoCompleteTag(this.clientContacts))
      this.listContact = _.cloneDeep(this.originalClientContacts)

      // Check if Bill To is undefined
      if (this.booking.billTo == undefined) {
        this.booking.billTo = this.oldBooking.billTo
      }
      // Check if Report To is undefined
      if (this.booking.reportTo == undefined) {
        this.booking.reportTo = this.oldBooking.reportTo
      }

      //set send report to data
      if (this.booking.reportTo != undefined && this.booking.reportTo != '') {
        var sendReportTo = this.listContact.find((x) => x.value.toLowerCase().includes(this.booking.reportTo.toLowerCase()))
        if (sendReportTo == undefined && this.booking.reportTo != "") {
          sendReportTo = {
            id: -1,
            name: "Other",
            value: 'Other'
          }
          this.showSendReportToOther = true
          this.reportToText = this.booking.reportTo
          this.booking.reportTo = 'Other'
        }
      }

      //setBill to email
      if (this.booking.billTo != undefined && this.booking.billTo != '') {
        var billTo = this.listContact.find((x) => x.value.toLowerCase().includes(this.booking.billTo.toLowerCase()))
        if (billTo == undefined && this.booking.billTo) {
          billTo = {
            id: -1,
            name: "Other",
            value: 'Other'
          }
          this.showBillToEmail = true
          this.billToText = this.booking.billTo
          this.booking.billTo = 'Other'
        }
      }

      setTimeout(() => {
        $(".dropdown-input").attr({
          autocomplete: "off",
        });
      }, 1000)

      this.originalStatus = _.cloneDeep(this.booking.bookingStatus)
      types.map((x) => {
        if (x.name == 'Brief' || x.name == 'Letter of Instruction' || x.name == 'Other') {
          this.fileTypes.push(x)
        }
      })
      this.openNotes()
      this.setBookingNoteAndAction()
      this.$store.dispatch("loading/setLoadComponent", false)
      this.$store.dispatch("setOriginalObject", this.booking)
      this.$store.dispatch("setNewObject", this.booking)
    },
  },

  async beforeMount() {
    await this.loadData()
    // Check if Claimant State During Booking is undefined
    if (this.booking.claimantStateDuringBooking == undefined) {
      this.booking.claimantStateDuringBooking = this.oldBooking.claimantStateDuringBooking
    }
    this.getOriginalBooking()
    this.loaded = true
    utilities.initTooltip()
    this.isSelected = false
  },

  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'})
    $('[data-toggle="tooltip"]').tooltip()

    setTimeout(() => {
      $(".report-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".report-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailSendTo.searchFilter = ""
        this.booking.reportTo = ""
        this.showSendReportToOther = false
      })

      $(".bill-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".bill-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailBillTo.searchFilter = ""
        this.booking.billTo = ""
        this.showBillToEmail = false
      })
    }, 1000)
  },
}
</script>
