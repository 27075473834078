/* eslint-disable */
<template>
  <div class="booking-request-form">
    <AdminNavigation ref="navigation" indexProps="1" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Booking Request Form</h1></div>
        <div class="header-sub-title">Booking request submitted by the Client, for action by Red Health.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="1" />
      <div class="content">
        <div v-if="bookingRequest != undefined">
          <div class="row mt-0">
            <strong class="mr-2">Claimant:</strong>{{ bookingRequest.claimantFullName }}
            <strong class="mr-2 ml-6">D.O.B:</strong>{{ formatDate(bookingRequest.dateOfBirth, "DD/MM/YYYY") }}
            <!-- <strong class="mr-2 ml-6">Commencement Date:</strong>{{ formatDate(bookingRequest.commencementDate, "DD/MM/YYYY") }} -->
            <strong v-if="bookingRequest.caseId != ''" class="mr-2 ml-6">Reference:</strong>{{ clientCase.caseReference }}
          </div>
          <div v-if="bookingRequest.clientId != ''" class="row">
            <strong class="mr-2">Client: </strong>{{ client.name }}
            <span v-if="client.primaryContact != undefined"><strong class="mr-2 ml-6">Contact: </strong>{{ getCaseOwnerName('fullName') }}</span>
            <span v-if="client.primaryContact != undefined"><strong class="mr-2 ml-6">Ph: </strong>{{ getCaseOwnerName('mobile')}}</span>
            <span v-if="client.primaryContact != undefined"><strong class="mr-2 ml-6">Email: </strong>{{ getCaseOwnerName('email') }}</span>
          </div>

          <div class="box">
            <div class="box-heading">
              <span>Booking Request Details</span>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Specialty, if known</div>
                <div class="box-col box-col-right">{{ bookingRequest.qualificationList }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Specialist</div>
                <div class="box-col box-col-right">{{ bookingRequest.fullName }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Type of Claim</div>
                <div class="box-col box-col-right">{{ bookingRequest.claimTypeName }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Claimant</div>
                <div class="box-col box-col-right">{{ bookingRequest.claimantFullName }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date of Birth</div>
                <div class="box-col box-col-right">{{ formatDate(bookingRequest.dateOfBirth, 'DD/MM/YYYY') }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Affected Body Areas</div>
                <div class="box-col box-col-right">{{ bookingRequest.bodyAreaList }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Conditions</div>
                <div class="box-col box-col-right">{{ bookingRequest.personConditionList }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Region / Location</div>
                <div class="box-col box-col-right">{{ bookingRequest.fullLocation }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Date Range</div>
                <div class="box-col box-col-right">{{ formatDate(bookingRequest.dateFrom, 'DD/MM/YYYY') + (bookingRequest.dateTo != '' ? ' - ' + formatDate(bookingRequest.dateTo, 'DD/MM/YYYY') : '') }}</div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Face to Face or Video Link Appointments</div>
                <div class="box-col box-col-right">
                  <img :src="bookingRequest.videoLinkAccepted ? require('@/assets/images/video_black_icon.svg') : require('@/assets/images/video_icon.svg')"
                    data-toggle="tooltip" data-placement="right" title="Video Link available" />
                </div>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="box-heading tab">
              <div class="tab-heading" @click="openTab(0)">Notes</div>
              <div class="tab-heading" @click="openTab(1)">Files</div>
            </div>
            <div class="box-body tab-content hidden-element">
              <div v-if="bookingRequest.note != ''" class="box-row">
                <div class="box-col box-col-left">{{ bookingRequest.userName }}</div>
                <div class="box-col box-col-left">{{ formatDate(bookingRequest.dateSubmitted, "DD/MM/YYYY") }}</div>
                <div style="width: width: 75%" class="box-col box-col-right box-col-long col-center-content">{{ bookingRequest.note }}</div>
              </div>
            </div>

            <div class="box-body tab-content hidden-element">
              <div class="p-3" v-for="(file, index) in bookingRequest.bookingRequestFile">
                <strong class="mr-2">ID:</strong>{{ file.bookingRequestFileId }} <strong class="mr-2 ml-6">By:</strong>{{ file.uploadedByName }} <strong class="mr-2 ml-6">Uploaded:</strong>{{ formatDate(bookingRequest.dateSubmitted, "DD/MM/YYYY") }} <strong class="mr-2 ml-6">File:</strong>{{ file.fileName }}
                <div class="is-icon right-float-element"><img @click="getFile(file)" src="@/assets/images/download_icon.svg" /></div>
              </div>
            </div>
          </div>

          <!-- <div class="box">
            <div class="box-heading">
              <span>Create Booking</span>
            </div>
            <div class="box-body">
              <div class="box-row">
                <div class="box-col box-col-left">Specialist</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="bookingRequest.specialistId" :class="hasError && !validateSpecialist ? 'is-invalid' : ''" @change="changeSpecialist">
                    <option hidden value="">Select ...</option>
                    <option v-for="(specialist, index) in specialists" :value="specialist.id">{{ specialist.fullName }}</option>
                  </select>
                  <div v-if="hasError && !validateSpecialist" class="text-danger">{{ specialistMessage }}</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Client Contact</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="clientContactId" :class="hasError && !validateClientContact ? 'is-invalid' : ''">
                    <option hidden value="">Select ...</option>
                    <option v-for="(clientContact, index) in clientContacts" :value="clientContact.userId">{{ clientContact.fullName }}</option>
                  </select>
                  <div class="text-danger" v-if="clientContacts.length == 0">No Client Contacts.</div>
                  <div v-if="hasError && !validateClientContact" class="text-danger">Client Contact is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Booking Status</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="booking.bookingStatus" :class="hasError && !validateBookingStatus ? 'is-invalid' : ''">
                    <option hidden value="">Select ...</option>
                    <option v-for="(bookingStatus, index) in bookingStatuses" :value="bookingStatus.id">{{ bookingStatus.name }}</option>
                  </select>
                  <div v-if="hasError && !validateBookingStatus" class="text-danger">Booking status is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Location</div>
                <div class="box-col box-col-right">
                  <select class="textbox w-100" v-model="booking.locationId" :class="hasError && !validateLocation ? 'is-invalid' : ''" @change="changeLocation">
                    <option hidden value="">Select ...</option>
                    <option v-for="(location, index) in locations" :value="location.id">{{ location.fullLocation }}</option>
                  </select>
                  <div v-if="hasError && !validateLocation" class="text-danger">Location is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col w-25">Date</div>
                <div class="box-col w-25">
                  <datepicker
                    v-model="booking.bookingDateTime"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    class="w-100 picker-height"
                    input-class="form-control"
                    :input-class="hasError && !validateBookingDate ? 'is-invalid' : ''"
                    :disabled-dates="disabledDates"
                    placeholder="dd/mm/yyyy" @selected="changeDate"></datepicker>
                    <div v-if="hasError && !validateBookingDate" class="text-danger">Date is required.</div>
                </div>
                <div class="box-col">
                  <span class="ml-10">&nbsp;</span>
                  <span class="ml-10">&nbsp;</span>
                  <span class="ml-10">Time</span>
                </div>
                <div class="box-col w-30">
                    <select
                      id="timepicker"
                      v-model="bookingStartTime"
                      @change="changeBookingTime"
                      class="textbox w-75 ml-4 custom-arrow">
                      <option hidden value="">Select ...</option>
                      <option  v-for="(item, index) in availableTimes" :value="item.time">{{ item.time }}</option>
                    </select>
                    <div v-if="hasError && !validateStartBookingTime" class="text-danger">Time is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Booking Type</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="booking.bookingTypeId" :class="hasError && !validateBookingType ? 'is-invalid' : ''" @change="changeBookingType">
                    <option hidden value="">Select ...</option>
                    <option v-for="(bookingType, index) in bookingTypes" :value="bookingType.id">{{ bookingType.name }}</option>
                  </select>
                  <div v-if="hasError && !validateBookingType" class="text-danger">Booking type is required.</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-col box-col-left">Booking Length</div>
                <div class="box-col box-col-right">
                  <select class="textbox" v-model="bookingLength" :class="hasError && !validateBookingLength ? 'is-invalid' : ''">
                    <option hidden value="">Select ...</option>
                    <option v-for="(bookingLength, index) in bookingLengths" :value="bookingLength.value">{{ bookingLength.text }}</option>
                  </select>
                  <div v-if="hasError && !validateBookingLength" class="text-danger">Booking length is required.</div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="alert alert-success hidden-element">
            <a class="close" title="close">×</a>
            <b>Success:</b> {{ successMessage }}
          </div>
          <div class="alert alert-danger hidden-element">
            <a class="close" title="close">×</a>
            <b>Error:</b> {{ errorMessage }}
          </div>

          <div class="icon-group right-float-element">
            <!-- <v-btn dark @click="convert">Convert to Booking</v-btn> -->
            <v-btn dark @click="searchAvailability">{{getName()}} </v-btn>
          </div>
        </div>
        <div v-else>This booking request not found.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminBookingsMenu from '@/components/AdminBookingsMenu'
  import Header from '@/components/Header'

  export default {
    name: 'AdminBookingRequestForm',
    components: {
      AdminNavigation,
      AdminBookingsMenu,
      Header,
    },
    data() {
      return {
        hasError: false,
        successMessage: "",
        errorMessage: "Please correct the errors shown above and try again.",
        specialistMessage: "",

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        bookingRequest: utilities.initBookingRequest(),
        client: utilities.initClient(),
        clientContactId: "",
        clientCase: utilities.initCase(),
        booking: utilities.initBooking(),
        bookingStartTime: "",
        bookingTime: "",
        bookingLength: "",
        specialistAvailabilityId: 0,
        minimumAppointment: utilities.initMinimumAppointment(),

        specialists: [],
        clientContacts: [],
        locations: [],
        bookingRequestStatuses: [],
        bookingStatuses: [],
        bookingTypes: [],
        bookingLengths: [],
        availableTimes: [],
        availability: [],

        disabledDates: {},
      }
    },
    computed: {
      validateSpecialist() {
        if(this.bookingRequest.specialistId == '') {
          this.specialistMessage = 'Specialist is required.'
          return false
        }
        // else if(this.specialistAvailabilityId == 0) {
        //   this.specialistMessage = 'The Specialist you have selected is not available. Please select another Specialist.'
        //   return false
        // }
        return true
      },
      validateClientContact() {
        if(this.clientContactId == '') {
          return false
        }
        return true
      },
      validateBookingStatus() {
        if (this.booking.bookingStatus == "") {
          return false
        }
        return true
      },
      validateLocation() {
        if (this.booking.regionId == "") {
          return false
        }
        return true
      },
      validateBookingDate() {
        if (this.booking.bookingDateTime == "") {
          return false
        }
        return true
      },
      validateBookingType() {
        if (this.booking.bookingTypeId == "") {
          return false
        }
        return true
      },
      validateBookingLength() {
        if (this.bookingLength == "") {
          return false
        }
        return true
      },
    },
    methods: {
      formatDate(dateToFormat, newFormat) {
        return utilities.formatDate(dateToFormat, newFormat)
      },

      getCaseOwnerName(type) {
        if (this.client != undefined && this.clientCase.caseOwner == '') {
          var caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
            if (type == "fullName") {
              return caseOwner.fullName
            } else if (type == "mobile") {
              return caseOwner.mobile
            } else if (type == "email") {
              return caseOwner.emailAddress
            }
          } else {
            return ' '
          }
        } else {
          var caseOwner = this.client.contacts.find((x) => x.userId == this.clientCase.caseOwner)
          if (caseOwner != undefined) {
            if (type == "fullName") {
              return caseOwner.fullName
            } else if (type == "mobile") {
              return caseOwner.mobile
            } else if (type == "email") {
              return caseOwner.emailAddress
            }
          } else {
            return ' '
          }
        }
      },


      openTab(index) {
        utilities.openTab(index)
      },
      getFile(file) {
        const params = {
          filetype: "brf",
          fn: file.fileName,
          id: this.bookingRequest.id
        }
        utilities.getDownloadFileUrlNew(params).then(res => {
          let a = document.createElement('a');
          let fileName = file.fileName
          a.target = '_blank'
          a.download = fileName;
          a.href = res
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(res);
          document.body.removeChild(a);
        })
      },
      getBookingRequestStatus(id) {
        return utilities.getBookingRequestStatus(id)
      },
      changeBookingType() {
        this.bookingLengths = []
        this.bookingLength = ""
        if(this.booking.bookingTypeId == 2 && this.minimumAppointment.independentMedicalAssessment > 0) {
          this.bookingLengths = utilities.getNewAppointmentLengths(this.minimumAppointment.independentMedicalAssessment)
        } else if(this.booking.bookingTypeId == 3 && this.minimumAppointment.independentMedicalAssessmentAbuse > 0) {
          this.bookingLengths = utilities.getNewAppointmentLengths(this.minimumAppointment.independentMedicalAssessmentAbuse)
        } else if(this.booking.bookingTypeId == 4 && this.minimumAppointment.independentMedicalAssessmentVideo > 0) {
          this.bookingLengths = utilities.getNewAppointmentLengths(this.minimumAppointment.independentMedicalAssessmentVideo)
        } else if(this.booking.bookingTypeId == 7 && this.minimumAppointment.phoneCall > 0) {
          this.bookingLengths = utilities.getNewAppointmentLengths(this.minimumAppointment.phoneCall)
        } else {
          this.bookingLengths = utilities.getAppointmentLengths()
        }
      },

      getName() {
        if(this.bookingRequest.bookingTypeName === 'Medical Negligence Request' || this.bookingRequest.bookingTypeName === 'Supplementary' || this.bookingRequest.bookingTypeName === 'File Review') {
          return 'Create Booking'
        }
        return 'Search for Availability'
      },

      async searchAvailability() {
        var bookingParams = {}
        if (this.bookingRequest.bookingTypeName == 'Medical Negligence Request' || this.bookingRequest.bookingTypeName == 'Supplementary' || this.bookingRequest.bookingTypeName == 'File Review') {
          await this.convert()

        } else {
          if(this.bookingRequest.claimantFullName != '' && this.bookingRequest.dateOfBirth != '') {
            var params = {
              fullname: this.bookingRequest.claimantFullName,
              dateOfBirth: moment(this.bookingRequest.dateOfBirth).format('YYYY/MM/DD'),
            }
            var searchCases = await utilities.getCasesByFullname(params)
            if(searchCases[0] != undefined) {
              var answer = await utilities.showWarningDialog("This claimant already exists under case Reference: " + searchCases[0].caseReference + ".  Would you like to attach this booking to that case?", true)
              if(answer) {
                this.bookingRequest.caseId = searchCases[0].id
                this.$router.push({ name: 'admin-search-specialist', query: { id:this.bookingRequest.caseId ,requestId: this.bookingRequest.id }}).catch((err) => {})
              } else {
                this.$router.push({ name: 'admin-search-specialist', query: { requestId: this.bookingRequest.id }}).catch((err) => {})
              }
            } else {
              this.$router.push({ name: 'admin-search-specialist', query: { requestId: this.bookingRequest.id }}).catch((err) => {})
            }
          }
        }
      },

      async convert() {
        if(!this.validateSpecialist || !this.validateBookingType) {
          this.hasError = true
          utilities.showAlert(".alert-danger")
          return false
        } else {
          this.hasError = false
        }
        // if(this.bookingRequest.claimantFullName != '' && this.bookingRequest.dateOfBirth != '') {
        //   var params = {
        //     fullname: this.bookingRequest.claimantFullName,
        //     dateOfBirth: moment(this.bookingRequest.dateOfBirth).format('YYYY/MM/DD'),
        //   }
        //   var searchCases = await utilities.getCasesByFullname(params)
        //   if(searchCases[0] != undefined) {
        //     var answer = await utilities.showWarningDialog("This claimant already exists under case Reference: " + searchCases[0].caseReference + ".  Would you like to attach this booking to that case?", true)
        //     if(answer) this.bookingRequest.caseId = searchCases[0].id
        //     else this.bookingRequest.caseId = ""
        //   }
        // }
        this.addBooking()
      },

      getLocationName(id) {
        if (id) {
          return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
        }
      },

      postBooking() {
        var bodyAreaObjects = []
        var bodyArea = {}
        this.bookingRequest.bodyAreas.map((x) => {
          if(x.bodyAreaId != "") {
            bodyArea = {
              BodyAreaID: x.bodyAreaId,
            }
            bodyAreaObjects.push(bodyArea)
          }
        })

        var personConditionObject = []
        var personCondition = {}
        this.bookingRequest.personConditions.map((x) => {
          if(x.personConditionId != "") {
            personCondition = {
              PersonConditionID: x.personConditionId,
            }
            personConditionObject.push(personCondition)
          }
        })

        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          ClientID: this.bookingRequest.clientId,
          CaseID: this.bookingRequest.caseId,
          CaseReference: this.clientCase.caseReference,
          SpecialistID: this.bookingRequest.specialistId,
          AvailabilityID: 0,
          DateCreated: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          ClaimTypeID: this.bookingRequest.claimTypeId,
          CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          ClaimantSurname: this.bookingRequest.claimantSurname,
          ClaimantGivenNames: this.bookingRequest.claimantGivenNames,
          ClaimantFirstName: this.bookingRequest.claimantFirstName,
          DateofBirth: this.bookingRequest.dateOfBirth,
          Salutation: this.clientCase.Salutation,
          PreferredGivenName: this.clientCase.PreferredGivenName,
          VideoLink: this.booking.bookingTypeId === 9 || this.booking.bookingTypeId === 4 || this.booking.bookingTypeId === 12 || this.booking.bookingTypeId === 15 ? 1 : 0,
          BodyAreaObject: bodyAreaObjects,
          BodyAreaOther: this.bookingRequest.bodyAreaOther,
          PersonConditionObject: personConditionObject,
          CaseOwner: this.clientContactId,
          CreatedBy: this.user.id,
          BookingStatus: 3,
          BookingStatusName: 'Booked',
          BookingTypeID: this.booking.bookingTypeId,
          BookingTypeName: this.bookingRequest.bookingTypeName,
          BookingDateTime: this.bookingRequest.dateSubmitted,
          BookingEndTime: '',
          BookingNoteObject: [],
          ClientZohoLink: this.client.zohoLink,
          SpecialistZohoLink: this.specialists.find(x => x.id == this.bookingRequest.specialistId).zohoLink,
          ZohoCaseID: this.clientCase.zohoCaseId,
          ZohoDateCreated: moment().format(),
          ZohoBookingDateTime: moment(this.bookingRequest.dateSubmitted).format(),
          ZohoClaimantID: this.clientCase.zohoClaimantId,
          ClientContactZohoLink: this.clientCase.zohoClaimantId,
          Specialty: this.bookingRequest.qualificationList
        }

        const contact = this.clientContacts.find((x) => x.userId == this.clientCase.caseOwner)
        if(contact) {
          params.ClientContactZohoLink = contact.zohoClientContactId
          params.ClientContactZohoName = contact.fullName
          params.CCFirstName = contact.firstName
          params.CCSurname = contact.surname
          params.CCPhone = contact.phoneNumber1
          params.CCEmail = contact.NotificationEmailAddress || contact.emailAddress
          params.CCZohoID = contact.zohoClientContactId
          params.BranchName = contact.branchName
          params.ClientCategory = this.client.clientCategory
          params.ClientState = this.client.state
        }
        // check if booking type is supplementary
        if (this.booking.bookingTypeId === 8) {
          params.BookingEndTime = ""
          params.BookingDateTime = ""
          params.OriginalBookingID = this.bookingRequest.originalBookingId
        }
        if(params.BookingStatus == 3) {
          params.BookingStatusName = 'Booked'
        }
        if(this.bookingRequest.note != '') {
          params.BookingNoteObject.push({
             Note: this.bookingRequest.note,
             NoteDateTime: window.moment(this.bookingRequest.dateSubmitted, 'YYYY-MM-DD HH:mm:ss.ssssss').format('YYYY-MM-DD HH:mm:ss'),
             UserID: this.bookingRequest.submittedBy,
          })
        }
        var callback = (response) => {
          this.booking.id = response
        }
        return utilities.postBooking(params, callback)
      },
      async postBookingFile() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,
          BookingID: this.booking.id,
          BookingRequestID: this.bookingRequest.id,
          MoveObject: [],
        }
        var callback = {}

        this.bookingRequest.bookingRequestFile.map(async (x) => {
          params.MoveObject.push({
            BookingRequestFileID: x.bookingRequestFileId,
            BookingID: this.booking.id,
            FileName: x.fileName,
            FileTypeID: x.fileTypeId,
            FileStatus: 1
          })
        })
        await utilities.postBookingFile(params, callback)
      },
      putBookingRequest() {
        var params = {
          UserID: this.user.id,
          Username: this.user.name,

          BookingRequestID: this.bookingRequest.id,
          // Completed
          BookingRequestStatus: 3,
        }
        var callback = (response) => {}
        return utilities.putBookingRequest(params, callback)
      },

      changeBookingTime() {
        this.availability.map((x) => {
          x.slots.map((i) => {
            if (i.time == this.bookingStartTime) {
              this.booking.specialistAvailabilityId = x.availabilityId
            }
          })
        })
      },
      async addBooking() {
        this.$store.dispatch("loading/setLoadComponent", true)
        var result = await this.postBooking()
        if(result) {
          await this.postBookingFile()
          await this.putBookingRequest()
          if(this.specialistAvailabilityId == 0) {
            this.successMessage = 'The booking has been created successfully. Please note, specialist availability did not exist for this booking.'
          } else {
            this.successMessage = "The booking has been created successfully"
          }

          utilities.showAlert(".alert-success")
          setTimeout(() => {
            this.$router.push({ name:'admin-manage-booking', query: { id: this.booking.id } }).catch((err) => {})
          }, 2000)
          this.$store.dispatch("loading/setLoadComponent", false)
        }
      },

      async loadBooking() {
        var bookingRequestId = (this.$route.query != undefined && this.$route.query.id != undefined) ? this.$route.query.id : ""
        if(bookingRequestId != '') {
          var bookingRequest = await utilities.getBookingRequestById(bookingRequestId)
          if(bookingRequest != undefined && Number(bookingRequest.status) != 3) {
            this.bookingRequest = bookingRequest

            this.booking.bookingTypeId = this.bookingRequest.bookingTypeId
            if(this.bookingRequest.clientId != '') this.client = await utilities.getClientById(this.bookingRequest.clientId)
            if(this.bookingRequest.caseId != '') this.clientCase = await utilities.getCaseByCaseId(this.bookingRequest.caseId)

            this.disabledDates = {
              ranges: [
                {
                  from: moment(utilities.START_DATE),
                  to: moment().subtract(1, 'd'),
                },
                {
                  from: moment(),
                }
              ],
            }
          } else {
            this.bookingRequest = undefined
          }
        }
      },
      async loadData(){
        if(this.bookingRequest != undefined) {
          const apiArr = [utilities.getSpecialists(), utilities.getLocations(), utilities.getBookingTypes()]
          const r = await Promise.all(apiArr)
          this.specialists = r[0]
          if(this.bookingRequest.clientId !== '') {
            utilities.getClientContacts(this.bookingRequest.clientId).then(res => {
              this.clientContacts = res
            })
          }
          this.locations = r[1]
          this.bookingRequestStatuses = utilities.getBookingRequestStatuses()
          var bookingStatuses = utilities.getBookingStatuses()
          this.bookingStatuses = bookingStatuses.filter(x => x.id == 2 || x.id == 3)
          this.bookingTypes = r[2]
          this.bookingTypes.splice(0, 1)
          this.bookingLengths = utilities.getAppointmentLengths()

          if (this.bookingRequest.submittedBy) {
            this.clientContactId = this.bookingRequest.submittedBy
          }
        }
      }
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadBooking()
      await this.loadData()
      this.openTab(0)
      utilities.initTooltip()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
    mounted() {},
  }
</script>
