/* eslint-disable */
<template>
  <div class="admin_bookings_awaiting_consent">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Admin Workflow</h1></div>
        <div class="header-sub-title"></div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu :key="bookingMenuIndex" :indexProps="bookingMenuIndex" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col">
            <div class="icon-group right-float-element">
              <el-input style="max-width: 120px" class="mr-1" @input="search()" clearable placeholder="Booking ID" v-model="listParam.BookingID"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Claimant Name" v-model="listParam.ClaimantName"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Client Name" v-model="listParam.ClientName"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Specialist Name" v-model="listParam.SpecialistName"/>
              <el-input style="max-width: 160px" class="mr-1" @input="search()" clearable placeholder="Assigned To" v-model="listParam.AssignedToName"/>
              <el-date-picker
                  style="width: 290px"
                  v-model="dateValue"
                  type="daterange"
                  :picker-options="pickerOptions"
                  start-placeholder="Booking Date Start"
                  popper-class="date-range-picker-popper"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  @change="changeDates"
                  end-placeholder="Booking Date End"
                  clearable
              />
            </div>
          </div>
        </div>

        <div class="content-scroll-x">
          <div>
            <div :class="bookings.length > 5 ? 'content-scroll-y' : ''">
              <el-table @row-click="RowClick" ref="bookingTable" :default-sort="{prop: 'id', order: 'descending'}" stripe @sort-change="sortParam" :data="bookingUnconfirmed" tooltip-effect="dark" :header-row-style="{background: '#fde9eb', height: '70px'}" :header-cell-style="{fontWeight: 100}">
                <el-table-column
                    width="100"
                    prop="id"
                    label="ID"
                    sortable="custom"
                    show-overflow-tooltip
                    fixed="left"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    sortable="custom"
                    show-overflow-tooltip
                    label="Claimant"
                    prop="claimantFullName"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="booingDate"
                    sortable="custom"
                    label="Booking Date"
                >
                  <template slot-scope="scope">
                    <span>{{
                        formatDate(scope.row.bookingDateTime, "DD/MM/YYYY")
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="300"
                    show-overflow-tooltip
                    label="Booking Type"
                    sortable="custom"
                    prop="bookingTypeName"
                >
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="clientName"
                    sortable="custom"
                    label="Client"
                >
                  <template slot-scope="booking">
                    <span>{{ decodeURIComponent(booking.row.clientName)}} - {{booking.row.branchName}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    prop="specialistFullName"
                    label="Specialist"
                    sortable="custom"
                >
                </el-table-column>
                <el-table-column
                    width="200"
                    prop="custom"
                    label="Paperwork Status"
                    show-overflow-tooltip
                >
                  <template slot="header">
                    <div class="d-flex align-items-center">
                      <span>Paperwork Status</span>
                      <img v-if="listParam.SortFlag !== 12 && listParam.SortFlag !== 14 && listParam.SortFlag !== 16" id="12" @click="customSort($event)"
                          class="is-icon w-10 ml-2"
                          src="@/assets/images/menu.svg"
                      />
                      <img v-if="listParam.SortFlag === 12" id="14" @click="customSort($event)"
                          class="is-icon w-10 ml-2"
                          src="@/assets/images/menu-1.svg"
                      />
                      <img v-if="listParam.SortFlag === 14" id="16" @click="customSort($event)"
                          class="is-icon w-10 ml-2"
                          src="@/assets/images/menu-2.svg"
                      />
                      <img v-if="listParam.SortFlag === 16" id="0" @click="customSort($event)"
                          class="is-icon w-10 ml-2"
                          src="@/assets/images/menu-3.svg"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="d-flex align-items-center">
                      <el-tooltip  effect="dark" content="LOI Received" placement="bottom">
                        <img
                            class="is-icon"
                            src="@/assets/images/lol-received.svg"
                            :class="isDate(scope.row.lolReceived) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip  effect="dark" content="Brief Received" placement="bottom">
                        <img
                            class="ml-4 is-icon"
                            src="@/assets/images/brief-received.svg"
                            :class="isDate(scope.row.briefReceived) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip  effect="dark" content="Paperwork Processed" placement="bottom">
                        <img
                            class="ml-4 is-icon"
                            src="@/assets/images/paperwork-processed.svg"
                            :class="isDate(scope.row.paperworkProcessed) ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    width="200"
                    prop="custom"
                    show-overflow-tooltip
                >
                  <template slot="header">
                    <div class="d-flex align-items-center">
                      <span>Booking Details</span>
                      <img v-if="listParam.SortFlag !== 18 && listParam.SortFlag !== 20 && listParam.SortFlag !== 22  && listParam.SortFlag !== 24" id="18" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4.svg"
                      />
                      <img v-if="listParam.SortFlag === 18" id="20" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-1.svg"
                      />
                      <img v-if="listParam.SortFlag === 20" id="22" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-2.svg"
                      />
                      <img v-if="listParam.SortFlag === 22" id="24" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-3.svg"
                      />
                      <img v-if="listParam.SortFlag === 24" id="0" @click="customSort($event)"
                           class="is-icon w-10 ml-2"
                           src="@/assets/images/menu4-4.svg"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="d-flex align-items-center">
                      <el-tooltip effect="dark" :content="getAttendanceTitle(scope.row)" placement="bottom">
                        <img
                            class="is-icon"
                            style="width: 34px"
                            src="@/assets/images/claimant_attendance_icon.svg"
                            :class="isDate(scope.row.claimantConfirmed) || scope.row.claimantConfirmed ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip effect="dark" :content="getCanRingClaimantTitle(scope.row)" placement="bottom">
                        <img
                            class="ml-2 is-icon"
                            style="width: 34px"
                            src="@/assets/images/can_ring_claimant_icon.svg"
                            :class="scope.row.redHealthMayContactClaimant ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                      <el-tooltip effect="dark" :content="getConsentReceivedTitle(scope.row)" placement="bottom">
                        <img
                            class="ml-2 is-icon"
                            style="width: 34px"
                            src="@/assets/images/consent_received_icon.svg"
                            :class="isDate(scope.row.claimantConsentReceived) || scope.row.claimantConsentReceived ? '' : 'disabled-element'"
                        />
                      </el-tooltip>
                    <el-tooltip effect="dark" :content="getHasVideoTitle(scope.row)" placement="bottom">
                      <img
                          v-if="scope.row.hasVideoLink"
                          class="ml-2 is-icon"
                          src="@/assets/images/video_link_test_icon.svg"
                          style="width: 34px"
                          :class="scope.row.videoLinkTestStatus === 2 ? '' : 'disabled-element'"
                      />
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    show-overflow-tooltip
                    label="ZohoAdminOwner"
                    prop="ZohoAdminOwner"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item"
                     :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount / listParam.PageSize) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsAwaitingConsent",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      dateValue: '',
      bookings: [],
      allBookings: [],
      user: _.cloneDeep(this.$store.getters['user/getUser']),
      limit: 10,
      users: [],
      totalCount: 0,
      pagination: utilities.initPagination(),
      bookingMenuIndex: 10,
      bookingUnconfirmed: [],
      allBookingUnconfirmed: [],
      loading: false,
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        BookingID: null,
        ClientName: '',
        ClaimantName: '',
        SpecialistName: '',
        AssignedToName: ''
      },
      userParam: {
        PageSize: 10,
        PageNum: 1,
        SortField: 'UserStatus',
        SortBy: 'DESC',
        UserName: null,
        RoleID: '3,6'
      },
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Number(window.moment().startOf('day').format('x'));
        },
      },
    }
  },
  computed: {
  },
  methods: {
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    changeDates(v) {
      if(v) {
        this.listParam.FromDate = v[0]
        this.listParam.ToDate = v[1]
      } else {
        this.listParam.FromDate = null
        this.listParam.ToDate = null
      }
      this.selectPage(1)
    },
    getHasVideoTitle(booking) {
      const title = "Video link test " + (booking.videoLinkTestStatus == 2 ? "" : "not ") + "completed"
      return title
    },
    getAttendanceTitle(booking) {
      const title = "Claimant attendance " + (this.isDate(booking.claimantConfirmed) || booking.claimantConfirmed ? "" : "not ") + "confirmed"
      return title
    },
    isDate(date) {
      return moment(date, "YYYY-MM-DD").isValid()
    },
    RowClick(row, col) {
      if(col.property === 'custom' || col.property === 'AssignedToName') return
      this.openBooking(row?.id)
    },
    customSort(sort) {
      this.listParam.SortFlag = Number(sort.target.id)
      this.selectPage(1)
    },
    async remoteMethod(query) {
      this.userParam.UserName = query
      this.loading = true
      let {users} = await utilities.getUsersNew(this.userParam)
      this.users = users.filter(item => item.status)
      this.loading = false
    },
    sortParam( v ) {
      if(v.prop === 'id' && v.order === "ascending") {
        this.listParam.SortFlag = 1
      } else if (v.prop === 'id' && v.order === "descending") {
        this.listParam.SortFlag = 2
      } else if(v.prop === 'claimantFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'claimantFullName' && v.order === "descending") {
        this.listParam.SortFlag = 4
      } else if(v.prop === 'dateCreated' && v.order === "ascending") {
        this.listParam.SortFlag = 15
      } else if (v.prop === 'dateCreated' && v.order === "descending") {
        this.listParam.SortFlag = 16
      } else if(v.prop === 'booingDate' && v.order === "ascending") {
        this.listParam.SortFlag = 5
      } else if (v.prop === 'booingDate' && v.order === "descending") {
        this.listParam.SortFlag = 6
      } else if(v.prop === 'clientName' && v.order === "ascending") {
        this.listParam.SortFlag = 7
      } else if (v.prop === 'clientName' && v.order === "descending") {
        this.listParam.SortFlag = 8
      } else if(v.prop === 'contactFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 3
      } else if (v.prop === 'contactFullName' && v.order === "descending") {
        this.listParam.SortFlag = 4
      } else if(v.prop === 'specialistFullName' && v.order === "ascending") {
        this.listParam.SortFlag = 9
      } else if (v.prop === 'specialistFullName' && v.order === "descending") {
        this.listParam.SortFlag = 10
      } else if (v.prop === 'bookingTypeName' && v.order === "descending") {
        this.listParam.SortFlag = 28
      } else if (v.prop === 'bookingTypeName' && v.order === "ascending") {
        this.listParam.SortFlag = 27
      } else if (v.prop === 'assignedToName' && v.order === "descending") {
        this.listParam.SortFlag = 26
      } else if (v.prop === 'assignedToName' && v.order === "ascending") {
        this.listParam.SortFlag = 25
      }
      this.selectPage(1)
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadData()
    },

    getCanRingClaimantTitle(booking) {
      var title = booking.redHealthMayContactClaimant ? "Can ring claimant" : "Can not ring claimant"
      return title
    },

    getConsentReceivedTitle(booking) {
      const title = "Claimant consent " + (this.isDate(booking.claimantConsentReceived) || booking.claimantConsentReceived ? "" : "not ") + "received"
      return title
    },

    async changeAssignedTo(row) {
      // Change status to assigned
      const result = await this.putBookingRequest(row)
      if (result) {
        this.$notify({
          dangerouslyUseHTMLString: true,
          message: '<div class="alert-success">' +
              '            <b>Success:</b> Booking updated !' +
              '          </div>',
        });
      }
    },

    putBookingRequest(row) {
      this.$store.dispatch('loading/setLoadComponent', true)
      const params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: row.id,
        AvailabilityID: row?.specialistAvailabilityId,
        AssignedTo: Number(row.assignedTo),
        BookingTypeID: row.bookingTypeId,
        BookingDateTime: utilities.formatDate(row.bookingDateTime, "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"),
        BookingEndTime: window.moment(row.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss')
      }
      var callback = () => {
        // Reload if change to Completed
        this.selectPage(this.listParam.PageNum)
        this.$store.dispatch('loading/setLoadComponent', false)
      }
      return utilities.putBooking(params, callback, true)
    },

    formatDate(dateToFormat, newFormat, currentFormat = "") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },

    openBooking(id) {
      let routeData = this.$router.resolve({ path: '/admin-manage-booking', query: { id: id } })
      window.open(routeData.href, '_blank')
    },

    async loadData() {
      this.$store.dispatch("loading/setLoadComponent", true)
      const {bookings, total} = await utilities.getBookingUnconfirmedNew(this.listParam)
      this.totalCount = total
      this.bookingUnconfirmed = _.cloneDeep(bookings)
      this.bookingUnconfirmed.filter(item => item.assignedTo).forEach(item => {
        if(this.users.filter(user => user.id === item.assignedTo).length === 0) {
          this.users.push({id: item.assignedTo, name: decodeURIComponent(item.assignedToName)})
        }
      })
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async loadUser() {
      var {users} = await utilities.getUsersNew(this.userParam)
      let arr = users.filter(item => item.status)
      for(let i = 0; i < arr.length; i++) {
        if(!this.users.find(item => item.id === arr[i].id)) {
          this.users.unshift(arr[i])
        } else {
          let index = this.users.findIndex(item => item.id === arr[i].id)
          this.users.splice(index, 1)
          this.users.unshift(arr[i])
        }
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    await this.loadUser()
    await this.loadData()
    this.$store.dispatch("loading/setLoadComponent", false)
  },
  mounted() {
    $(".pagination").hide()
  },
}
</script>
